<template>
  <!-- eslint-disable -->
  <div v-if="$route.name!=='docs' && profile == false">

  <div v-if="step == 'password-reset'" class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed" style="background-image: url(assets/media/illustrations/dozzy-1/14.png">
    <!--begin::Content-->
    <div class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
      <!--begin::Wrapper-->
      <div class="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
        <!--begin::Form-->
        <form class="form w-100" novalidate="novalidate">
          <!--begin::Heading-->
          <div class="text-center mb-10">
            <!--begin::Title-->
            <h1 class="text-dark mb-3">Zapomniałeś hasła?</h1>
            <!--end::Title-->
            <!--begin::Link-->
            <div class="text-gray-400 fw-bold fs-4">Podaj swój adres e-mail</div>
            <!--end::Link-->
          </div>
          <!--begin::Heading-->
          <!--begin::Input group-->
          <div class="fv-row mb-10">
            <label class="form-label fw-bolder text-dark fs-6">E-mail</label>
            <input type="email" :class="{ 'is-invalid': errors.email }" class="form-control form-control-lg form-control-solid" placeholder="" v-model="username" autocomplete="off" />
            <div class="fv-plugins-message-container invalid-feedback">
              <span v-for="(element, index) in errors.email">
                <span v-if="index != 0"><br> </span><span>{{ element }}</span>
              </span>
            </div>
          </div>
          <!--end::Input group-->
          <!--begin::Actions-->
          <div class="d-flex flex-wrap justify-content-center pb-lg-0">
            <button @click="remind" type="button" class="btn btn-lg btn-primary fw-bolder me-4">
              <span class="indicator-label">Wyślij kod</span>
              <span class="indicator-progress">Please wait...
              <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
            </button>
            <button type="button" @click="step = ''" class="btn btn-lg btn-secondary fw-bolder">
              <span class="indicator-label">Powrót</span>
            </button>
          </div>
          <!--end::Actions-->
        </form>
        <!--end::Form-->
      </div>
      <!--end::Wrapper-->
    </div>
    <!--end::Content-->
    <!--begin::Footer-->
    <div class="d-flex flex-center flex-column-auto p-10">
      <!--begin::Links-->
      <div class="d-flex align-items-center fw-bold fs-6">
        <a href="https://www.gunb.gov.pl" class="text-muted text-hover-primary px-2">Główny Urząd Nadzoru Budowlanego</a>
      </div>
      <!--end::Links-->
    </div>
    <!--end::Footer-->
  </div>
  <div v-else-if="step == 'register'" class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
    <div class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
      <div v-if="codeExpired" class="w-lg-600px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
        <div class="text-center mb-15">
          <h1 class="text-dark mb-3">Wysłany kod wygasł</h1>
          <div class="text-muted fw-bold fs-5 mb-10">Minęło 5 minut od momentu wysłania kodu.</div>
          <div class="fw-bolder text-dark fs-6 mb-5">Możesz ponownie otrzymać kod na adres e-mail</div>
          <div class="fw-bolder text-dark fs-3">{{ username }}</div>
        </div>
        <div class="text-center">
          <button type="button" @click="register" class="btn btn-lg btn-primary">
            <span class="indicator-label">Wyślij ponownie</span>
            <span class="indicator-progress">Please wait...
            <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
          </button>
          <button type="button" @click="codeExpired = false" class="btn btn-lg btn-secondary fw-bolder">
            <span class="indicator-label">Powrót</span>
          </button>
        </div>
      </div>
      <div v-else class="w-lg-600px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
        <div class="mb-10 text-center">
          <h1 class="text-dark mb-3">Utwórz konto</h1>
          <div class="text-gray-400 fw-bold fs-4">Masz już konto?
          <a @click="step = ''" class="link-primary fw-bolder cursor-pointer">Zaloguj się</a></div>
          <!--end::Link-->
        </div>
        <div class="row fv-row mt-10 mb-7">
          <!--begin::Col-->
          <div class="col-xl-6">
            <label class="form-label fw-bolder text-dark fs-6">Imię</label>
            <input type="text" :class="{ 'is-invalid': errors.imie }" class="form-control form-control-lg form-control-solid" placeholder="" v-model="firstname" autocomplete="off" />
            <div class="fv-plugins-message-container invalid-feedback">
              <span v-for="(element, index) in errors.imie">
                <span v-if="index != 0"><br> </span><span>{{ element }}</span>
              </span>
            </div>
          </div>
          <!--end::Col-->
          <!--begin::Col-->
          <div class="col-xl-6">
            <label class="form-label fw-bolder text-dark fs-6">Nazwisko</label>
            <input type="text" :class="{ 'is-invalid': errors.nazwisko }" class="form-control form-control-lg form-control-solid" placeholder="" v-model="lastname" autocomplete="off" />
            <div class="fv-plugins-message-container invalid-feedback">
              <span v-for="(element, index) in errors.nazwisko">
                <span v-if="index != 0"><br> </span><span>{{ element }}</span>
              </span>
            </div>
          </div>
          <!--end::Col-->
        </div>
        <!--end::Input group-->
        <!--begin::Input group-->
        <div class="fv-row mb-5">
          <label class="form-label fw-bolder text-dark fs-6">E-mail</label>
          <input type="email" :class="{ 'is-invalid': errors.email }" class="form-control form-control-lg form-control-solid" placeholder="" v-model="username" autocomplete="off" />
          <div class="fv-plugins-message-container invalid-feedback">
            <span v-for="(element, index) in errors.email">
              <span v-if="index != 0"><br> </span><span>{{ element }}</span>
            </span>
          </div>
        </div>
        <!--end::Input group-->
        <!--begin::Input group-->
        <div class="mb-5 fv-row">
          <!--begin::Wrapper-->
          <div class="mb-1">
            <div class="position-relative mb-3">
              <label class="form-label fw-bolder text-dark fs-6">Hasło</label>
              <input type="password" :class="{ 'is-invalid': errors.haslo}" class="form-control form-control-lg form-control-solid" placeholder="" v-model="password1" autocomplete="off" />
              <div class="fv-plugins-message-container invalid-feedback">
                <span v-for="(element, index) in errors.haslo">
                  <span v-if="index != 0"><br> </span><span>{{ element }}</span>
                </span>
              </div>
            </div>
          </div>
          <!--end::Wrapper-->
          <!--begin::Hint-->
          <div class="text-muted">Użyj 10 lub więcej znaków, co najmniej jednej małej litery, jednej dużej litery, jednej cyfry i jednego znaku specjalnego.</div>
          <!--end::Hint-->
        </div>
        <!--end::Input group=-->
        <!--begin::Input group-->
        <div class="fv-row mb-5">
          <label class="form-label fw-bolder text-dark fs-6">Powtórz hasło</label>
          <input type="password" :class="{ 'is-invalid': errors.haslo_confirmation }" class="form-control form-control-lg form-control-solid" placeholder="" v-model="password2" autocomplete="off" />
          <div class="fv-plugins-message-container invalid-feedback">{{ errors.haslo_confirmation }}</div>
        </div>
        <!--end::Input group-->
        <!--begin::Input group-->
        <div class="fv-row mb-10">
          <label :class="{ 'is-invalid': errors.rodo }" class="form-check form-check-custom form-check-solid mb-5">
            <input type="checkbox" class="form-check-input" name="rodo" value="1" v-model="rodo" :checked="rodo" />
            <span class="form-check-label fw-bold text-gray-700 fs-6">Otrzymałem do przeczytania
            <a data-bs-toggle="modal" data-bs-target="#modalRODO" class="ms-1 link-primary">klauzulę informacyjną RODO</a>.</span>
          </label>
          <div class="fv-plugins-message-container invalid-feedback">
            <span v-for="(element, index) in errors.rodo">
              <span v-if="index != 0"><br> </span><span>{{ element }}</span>
            </span>
          </div>
        </div>
        <!--end::Input group-->
        <!--begin::Actions-->
        <div class="text-center">
          <button type="button" @click="register" class="btn btn-lg btn-primary">
            <span class="indicator-label">Potwierdź e-mail</span>
            <span class="indicator-progress">Please wait...
            <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
          </button>
          <button type="button" @click="step = ''" class="btn btn-lg btn-secondary fw-bolder mx-5">
            <span class="indicator-label">Powrót</span>
          </button>
        </div>
        <!--end::Actions-->
      </div>
    </div>
  </div>
  <div v-else-if="step == 'new-password'" class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
    <div class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
      <div class="w-lg-550px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
        <div class="text-center mb-10">
          <h1 class="text-dark mb-3">Ustaw nowe hasło</h1>
        </div>
        <div class="mb-5 fv-row">
          <div class="mb-1">
            <label class="form-label fw-bolder text-dark fs-6">
              <span v-for="(element, index) in errors.haslo">
                <span v-if="index != 0"><br> </span><span>{{ element }}</span>
              </span>
            </label>
            <div class="position-relative mb-3">
              <label class="form-label fw-bolder text-dark fs-6">Hasło</label>
              <input type="password" :class="{ 'is-invalid': errors.haslo }" class="form-control form-control-lg form-control-solid" placeholder="" v-model="password1" autocomplete="off" />
              <div class="fv-plugins-message-container invalid-feedback">
                <span v-for="(element, index) in errors.haslo">
                  <span v-if="index != 0"><br> </span><span>{{ element }}</span>
                </span>
              </div>
            </div>
          </div>
          <div class="text-muted">Użyj 10 lub więcej znaków, co najmniej jednej małej litery, jednej dużej litery, jednej cyfry i jednego znaku specjalnego.</div>
        </div>
        <div class="fv-row mb-5">
          <label class="form-label fw-bolder text-dark fs-6">Powtórz hasło</label>
          <input type="password" :class="{ 'is-invalid': errors.haslo_confirmation }" class="form-control form-control-lg form-control-solid" placeholder="" v-model="password2" autocomplete="off" />
          <div class="fv-plugins-message-container invalid-feedback">{{ errors.haslo_confirmation }}</div>
        </div>
        <div class="fv-row mb-10">
          <label :class="{ 'is-invalid': errors.rodo }" class="form-check form-check-custom form-check-solid mb-5">
            <input type="checkbox" class="form-check-input" name="rodo" value="1" v-model="rodo" :checked="rodo" />
            <span class="form-check-label fw-bold text-gray-700 fs-6">Otrzymałem do przeczytania
            <a href="#" class="ms-1 link-primary">klauzulę informacyjną RODO</a>.</span>
          </label>
          <div class="fv-plugins-message-container invalid-feedback">
            <span v-for="(element, index) in errors.rodo">
              <span v-if="index != 0"><br> </span><span>{{ element }}</span>
            </span>
          </div>
        </div>
        <div class="text-center">
          <button type="button" @click="remind" class="btn btn-lg btn-primary fw-bolder">
            <span class="indicator-label">Ustaw hasło</span>
            <span class="indicator-progress">Please wait...
            <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
          </button>
          <button type="button" @click="cancelCode" class="btn btn-lg btn-secondary fw-bolder mx-5">
            <span class="indicator-label">Powrót</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="step == 'register-confirm' || step == 'password-reset-confirm' || step == 'register-resend' || step == 'password-reset-resend' || step == '2fa-resend' || step == '2fa-confirm'" class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
    <div class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
      <div class="w-lg-600px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
        <div v-if="step == '22fa-resend' || step == '22fa-confirm'" class="text-center mb-10">
          <img alt="Logo" class="mh-125px" src="@/assets/media/svg/misc/smartphone.svg" />
        </div>
        <div v-else class="ms-auto text-center mb-10">
          <i class="fas fa-envelope-open-text" style="font-size: 80px; color: #6f7077"></i>
        </div>
        <div class="text-center mb-10">
          <h1 v-if="step == 'register-confirm'" class="text-dark mb-3">Potwierdzenie adresu e-mail</h1>
          <h1 v-else-if="step == 'password-reset-confirm'" class="text-dark mb-3">Przypomnienie hasła</h1>
          <h1 v-else-if="step == '2fa-confirm'" class="text-dark mb-3">Dwustopniowa autoryzacja</h1>
          <div class="text-muted fw-bold fs-5 mb-5">Wprowadź kod, który wysłaliśmy na adres e-mail</div>
          <div class="fw-bolder text-dark fs-3">{{ username }}</div>
        </div>
        <div class="mb-10 px-md-10">
          <div class="fw-bolder text-start text-dark fs-6 mb-1">Podaj 6-cyfrowy kod z wiadomości e-mail</div>
          <div class="d-flex flex-wrap justify-content-between" :class="{ 'is-invalid': errors.code }">
            <input type="text" maxlength="1" class="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover me-2 my-2" ref="digits0" name="digits0" @paste="paste" v-model.number="digit0" @keypress="nextDigit" @keyup.right="nextDigit" />
            <input type="text" maxlength="1" class="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover me-2 my-2" ref="digits1" name="digits1" v-model.number="digit1" @keypress="nextDigit" @keyup.left="prevDigit" @keyup.right="nextDigit" />
            <input type="text" maxlength="1" class="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover me-2 my-2" ref="digits2" name="digits2" v-model.number="digit2" @keypress="nextDigit" @keyup.left="prevDigit" @keyup.right="nextDigit" />
            <input type="text" maxlength="1" class="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover me-2 my-2" ref="digits3" name="digits3" v-model.number="digit3" @keypress="nextDigit" @keyup.left="prevDigit" @keyup.right="nextDigit" />
            <input type="text" maxlength="1" class="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover me-2 my-2" ref="digits4" name="digits4" v-model.number="digit4" @keypress="nextDigit" @keyup.left="prevDigit" @keyup.right="nextDigit" />
            <input type="text" maxlength="1" class="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover my-2" ref="digits5" name="digits5" v-model.number="digit5" @keypress="nextDigit" @keyup.left="prevDigit" />
          </div>
          <div class="fv-plugins-message-container invalid-feedback">{{ errors.code }}</div>
        </div>
        <!--end::Section-->
        <!--begin::Submit-->
        <div class="d-flex flex-center">
          <button type="button" @click="confirmCode" class="btn btn-lg btn-primary fw-bolder" :disabled="!readyToSend">
            <span v-if="step == 'register-confirm'" class="indicator-label">Zarejestruj konto</span>
            <span v-else class="indicator-label">Przejdź dalej</span>
            <span class="indicator-progress">Please wait...
            <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
          </button>
          <button type="button" @click="cancelCode" class="btn btn-lg btn-secondary fw-bolder mx-5">
            <span class="indicator-label">Powrót</span>
          </button>
        </div>
        <div class="text-center fw-bold fs-5 mt-15">
          <p class="text-muted me-1">Kod jest ważny przez 5 minut</p>
          <span class="text-muted me-1">Nie otrzymałeś kodu?</span>
          <a v-if="codeResentCounter <= 0" @click="resendCode" class="link-primary fw-bolder fs-5 me-1 cursor-pointer">Wyślij ponownie</a>
          <span v-else class="text-muted me-1">Wyślij ponownie za <code class="text-bold">{{ codeResentTime }}</code></span>
        </div>
      </div>
    </div>
  </div>
  <!--end::Authentication - Password reset-->
  <!--begin::Authentication - Sign-in -->
  <div v-else class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
    <!--begin::Content-->
    <div class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
      <div class="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
        <!--begin::Form-->
        <form class="form w-100" novalidate="novalidate" id="kt_sign_in_form">
          <!--begin::Heading-->
          <div class="text-center mb-8">
            <!--begin::Title-->
            <!--<h1 class="text-dark mb-3">Zaloguj się</h1>-->
            <img alt="Logo" class="mh-250px" src="@/assets/media/api-closed.png" />
            <h1 class="text-dark mb-3">Uwaga!</h1>
            <!--end::Title-->
            <!--begin::Link-->
            <!--
            Currently We dont want to allow new users
            <div class="text-gray-400 fw-bold fs-4">xNie masz konta?
            <a @click="errors = {}; step = 'register'" class="link-primary fw-bolder cursor-pointer">Wypełnij wniosek</a></div>
            -->
            <div class="fs-4"><strong>Informujemy, że system RWDZ jest w przebudowie.</strong> API systemu po zmianach zostanie udostępnione w drugim kwartale 2026 r.</div>
            <div class="fs-4">Przepraszamy za utrudnienia. Do zobaczenia w nowej wersji.</div>
            <div class="fs-4">&nbsp;</div>
            <div class="fs-4">Jeśli masz już konto, możesz się zalogować tutaj.</div>
            <!--end::Link-->
          </div>
          <!--begin::Heading-->
          <!--begin::Input group-->
          <div class="fv-row mb-8">
            <!--begin::Label-->
            <label class="form-label fs-6 fw-bolder text-dark">E-mail</label>
            <!--end::Label-->
            <!--begin::Input-->
            <input class="form-control form-control-lg form-control-solid" type="text" name="email" autocomplete="off" v-model="username" />
            <!--end::Input-->
          </div>
          <!--end::Input group-->
          <!--begin::Input group-->
          <div class="fv-row mb-10">
            <!--begin::Wrapper-->
            <div class="d-flex flex-stack mb-2">
              <!--begin::Label-->
              <label class="form-label fw-bolder text-dark fs-6 mb-0">Hasło</label>
              <!--end::Label-->
              <!--begin::Link-->
              <a @click="errors = {}; step = 'password-reset'" class="link-primary fs-6 fw-bolder cursor-pointer">Zapomniałeś hasła?</a>
              <!--end::Link-->
            </div>
            <!--end::Wrapper-->
            <!--begin::Input-->
            <input :class="{ 'is-invalid': errors.password }" class="form-control form-control-lg form-control-solid" type="password" autocomplete="off" v-model="password" />
            <div class="fv-plugins-message-container invalid-feedback">
              <span v-for="(element, index) in errors.password">
                <span v-if="index != 0"><br> </span><span>{{ element }}</span>
              </span>
            </div>
            <!--end::Input-->
          </div>
          <!--end::Input group-->
          <!--begin::Actions-->
          <div class="text-center">
            <!--begin::Submit button-->
            <button type="button" class="btn btn-lg btn-primary w-100 mb-5" @click="login()">
              <span v-if="loginInProgress" class="indicator-label">Proszę czekać...
                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
              <span v-else class="indicator-label">Przejdź dalej</span>
            </button>
            <!--end::Submit button-->
          </div>
          <!--end::Actions-->
        </form>
        <!--end::Form-->
      </div>
      <!--end::Wrapper-->
    </div>
    <!--end::Content-->
    <!--begin::Footer-->
    <div class="d-flex flex-center flex-column-auto p-10">
      <!--begin::Links-->
      <div class="d-flex align-items-center fw-bold fs-6">
        <a href="https://www.gunb.gov.pl" class="text-muted text-hover-primary px-2">Główny Urząd Nadzoru Budowlanego</a>
      </div>
      <!--end::Links-->
    </div>
    <!--end::Footer-->
  </div>
  <!--end::Authentication - Sign-in-->

  </div>
</template>

<script>
export default {
  name: 'API',
  data() {
    return {
      step: '',
      /*accessToken: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI5MGU2N2M5My02NjMwLTQ3MGYtOTFlOC0wNDRkNTNlNGYwMDAiLCJqdGkiOiJjMzVjYWU5MjRhNDA0NjRmYmQ1NGJhMjViYzA2ZTNkYjQwZGU0OTcxMjU4MTg3YTAwNzQ3YTNhOWYwYTFhMjc0MmZjZjNmMzBkNjdhMGIxZCIsImlhdCI6MTY0Mjk4MjUwMCwibmJmIjoxNjQyOTgyNTAwLCJleHAiOjE2NzQ1MTg1MDAsInN1YiI6IjIiLCJzY29wZXMiOlsiKiJdfQ.IP1iA-xCfhHM0XR2a0X1PLP82Ws9sx3nFxdLHy_6GI4KVtI--xghoLSG3a75GBYD5kd3MxjmuTceQxZei7VbozN9Kz5HtFrT3C1jOlJortlIcqJ0Kse0TbOlNzQo-yYg6kBITC_CY-LZAi_SRk-xzplsvUyDKWWOVPYjr-JSBVBLRssZJcaD4a7bHnhS4bL9B-mjIyXAEHVVzNYMSPCYC7TOjQNb8bL8LQEgNfrVTptyqUVAtlABohsJH2AJ2ZnubRh7s6bvDaIFXGzRkiLNn-dobzBhz1Pt68e_A7UKoR_B2-6xkmeOciPkQy1RLPJjJHZYWjiJOYhPfAh4-oQgipAtjIcfHzYkdceqpCb8uCPcaLCnEU_AMRzJw4VSexVRvw2OdwuG5hqawC0mFyNUdMI7FCNG7MBBwzyboV7nbzZIajPLZOoU8pbNlV8lGLTlWldxbuDb9Cg75I7rgONJjKhX3Ygj5PocZZXBkS3KUcbOPbrQUtbp6znN9P4XaXSWEBcs6zxNf5ov84M3uoZZnq2aU0lN9-hTVlNMcI3jIhL6Ma8HoDssw-wL1OE5XIO_GI1RpYKo7zb-4wdprEFcNnkmIiqZmpeEYlYT18NyrivsN0b-9WoXtWLkFE8DW1qF2QiAcB5KbgymS_2rdPZ64s8cDqKspZBiDbUnli3_n6I',*/
      refreshToken: false,
      loginInProgress: false,
      username: '',
      password: '',
      firstname: '',
      lastname: '',
      password1: '',
      password2: '',
      digit0: '',
      digit1: '',
      digit2: '',
      digit3: '',
      digit4: '',
      digit5: '',
      rodo: false,
      totp_secret: '',
      codeExpired: false,
      codeResentCounter: 60,
      codeResentTime: '',
      registerForm: false,
      readyToSend: false,
      organisation: {},
      errors: {},
      expirationInterval: false,
      codeResentInterval: false
    }
  },
  inject: ['$profile'],
  emits: ['login', 'logout'],
  mounted() {
    console.log(this.accessToken);
    if (this.accessToken)
    {
      var self = this;
      this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.accessToken;

      this.$http.get(process.env.VUE_APP_DEVPORTAL_API + 'me')
      .then(function (response) {
          if (typeof response.data.uuid !== 'undefined')
          {
              self.$emit('login', response.data);
          }
          self.loginInProgress = false;
          return;
      })
      .catch(function () {
        self.loginInProgress = false;
        return;
      });
    }
  },
  methods: {
    get: function (query) {
      var url = process.env.VUE_APP_DEVPORTAL_API + query;
      return this.$http.get(url);
    },
    patch: function (query, data = {}) {
     var url = process.env.VUE_APP_DEVPORTAL_API + query;
     return this.$http.patch(url, data);
    },
    post: function (query, data = {}) {
     var url = process.env.VUE_APP_DEVPORTAL_API + query;
     return this.$http.post(url, data);
    },
    delete: function (query) {
     var url = process.env.VUE_APP_DEVPORTAL_API + query;
     return this.$http.delete(url);
    },
    login: function() {
      if (this.loginInProgress)
      {
          return false;
      }
      var self = this;
      if (!this.username.includes('@'))
      {
          return false;
      }

      console.log(process.env.VUE_APP_DEVPORTAL_API_ID);

      var params = {
        grant_type: 'password',
        client_id: process.env.VUE_APP_DEVPORTAL_API_ID,
        client_secret: process.env.VUE_APP_DEVPORTAL_API_SECRET,
        scope: '*',
        username: this.username,
        password: this.password
      };

      var code = this.digit0.toString() + this.digit1.toString() + this.digit2.toString() + this.digit3.toString() + this.digit4.toString() + this.digit5.toString();

      if (this.step == '2fa-resend')
      {
        params.totp_secret = self.totp_secret;
      }
      else if (this.step == '2fa-confirm' && code.length == 6)
      {
        params.totp_secret = self.totp_secret;
        params.code = code;
      }
      else
      {
        self.totp_secret = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < 32; i++ ) {
          self.totp_secret += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        params.totp_secret = self.totp_secret;
      }

      this.$http.post(process.env.VUE_APP_DEVPORTAL_API + 'oauth/token', params)
      .then(function (response) {
        self.errors = {};
        if (typeof response.data.access_token !== 'undefined' &&
            typeof response.data.refresh_token !== 'undefined')
        {
            self.accessToken = response.data.access_token;
            self.refreshToken = response.data.refresh_token;
            self.$http.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access_token;
            self.password = '';
            self.step = '';
            self.$http.get(process.env.VUE_APP_DEVPORTAL_API + 'me')
            .then(function (response) {
                if (typeof response.data.uuid !== 'undefined')
                {
                    self.$emit('login', response.data);
                }
                self.loginInProgress = false;
                return;
            })
            .catch(function () {
              self.loginInProgress = false;
              return;
            });
        }
        else if (response.data) {
            return self.codeForm(response);
        }
        self.loginInProgress = false;
        return;
      })
      .catch(function (error) {
        if (error && error.response && error.response.data)
        {
          self.errors = error.response.data;
        }
        else
        {
          self.errors = {};
        }
        self.loginInProgress = false;
        return;
      });
      return;
    },
    logout: function() {
      this.accessToken = false;
      this.refreshToken = false;
      this.loginInProgress = false;
      this.password = '';
      this.profile = {};
      this.errors = {};
      this.$emit('logout');
    },
    remind: function() {
      if (this.loginInProgress)
      {
          return false;
      }

      var self = this;
      self.errors = {};

      var params = {
          email: this.username
      };

      var code = this.digit0.toString() + this.digit1.toString() + this.digit2.toString() + this.digit3.toString() + this.digit4.toString() + this.digit5.toString();

      if (this.step == 'password-reset-resend')
      {
        params.totp_secret = self.totp_secret;
      }
      else if (this.step == 'password-reset-confirm' && code.length == 6)
      {
        params.totp_secret = self.totp_secret;
        params.code = code;
      }
      else if (this.step == 'new-password' && code.length == 6)
      {
        params.totp_secret = self.totp_secret;
        params.code = code;
        params.haslo = self.password1;
        params.haslo_confirmation = self.password2;
        params.rodo = self.rodo;
      }
      else
      {
        self.totp_secret = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < 32; i++ ) {
          self.totp_secret += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        params.totp_secret = self.totp_secret;
      }

      this.$http.post(process.env.VUE_APP_DEVPORTAL_API + 'users/remindPassword', params)
      .then(self.codeForm)
      .catch(function (error) {
        self.loginInProgress = false;
        self.errors = error.response.data;
        return;
      });
      return;
    },
    register: function() {
      if (this.loginInProgress)
      {
          return false;
      }
      var self = this;
      self.errors = {};

      var params = {
          imie: this.firstname,
          nazwisko: this.lastname,
          email: this.username,
          haslo: this.password1,
          haslo_confirmation: this.password2,
          rodo: this.rodo,
          totp_secret: '',
          client_id: process.env.VUE_APP_DEVPORTAL_API_ID
        };

      var code = this.digit0.toString() + this.digit1.toString() + this.digit2.toString() + this.digit3.toString() + this.digit4.toString() + this.digit5.toString();

      if (this.step == 'register-resend')
      {
        params.totp_secret = self.totp_secret;
      }
      else if (this.step == 'register-confirm' && code.length == 6)
      {
        params.totp_secret = self.totp_secret;
        params.code = code;
      }
      else
      {
        self.totp_secret = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < 32; i++ ) {
          self.totp_secret += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        params.totp_secret = self.totp_secret;
      }

      this.$http.post(process.env.VUE_APP_DEVPORTAL_API + 'users', params)
      .then(self.codeForm)
      .catch(function (error) {
        self.loginInProgress = false;
        self.errors = error.response.data;
        return;
      });
      return;
    },
    codeForm: function (response) {
      var self = this;
      self.errors = {};

      if (!response.data)
      {
        return;
      }

      if (self.step == 'register-confirm')
      {
        if (typeof response.data.access_token !== 'undefined')
        {
          this.accessToken = response.data.access_token;

          self.$http.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access_token;
          self.password = '';
          self.step = '';
          self.$http.get(process.env.VUE_APP_DEVPORTAL_API + 'me')
          .then(function (response) {
              if (typeof response.data.uuid !== 'undefined')
              {
                  self.$emit('login', response.data);
              }
              self.loginInProgress = false;
              return;
          })
          .catch(function () {
            self.loginInProgress = false;
            return;
          });
        }
        else
        {
          self.step = '';
          self.password = self.password1;
          self.login();
          return;
        }
      }
      else if (self.step == 'password-reset-confirm')
      {
        self.step = 'new-password';
        return;
      }
      else if (self.step == 'new-password')
      {
        self.step = '';
        self.password = self.password1;
        self.login();
        return;
      }
      else if (self.step == '2fa-confirm')
      {
        self.step = '';
        self.login();
        return;
      }

      if (self.step == 'register' || self.step == 'register-resend')
      {
        self.step = 'register-confirm';
      }
      else if (self.step == 'password-reset' || self.step == 'password-reset-resend')
      {
        self.step = 'password-reset-confirm';
      }
      else if (self.step == '' || self.step == '2fa-resend')
      {
        self.step = '2fa-confirm';
      }

      self.loginInProgress = false;
      self.codeExpired = false;
      self.readyToSend = false;
      self.codeResentCounter = 60;
      self.codeResentTime = '01:00';
      self.digit0 = '';
      self.digit1 = '';
      self.digit2 = '';
      self.digit3 = '';
      self.digit4 = '';
      self.digit5 = '';
      clearInterval(self.expirationInterval);
      clearInterval(self.codeResentInterval);
      self.expirationInterval =  setInterval(
          function()
          {
            if (self.step == 'register-confirm')
            {
              self.codeExpired = true;
              self.step = 'register';
            }
          }, 300000);
      self.codeResentInterval =  setInterval(
          function()
          {
            self.codeResentCounter--;
            if (self.codeResentCounter <= 0)
            {
              clearInterval(self.codeResentInterval);
            }
            self.codeResentTime = self.codeResentCounter;
            if (self.codeResentTime < 10)
            {
              self.codeResentTime = '0' + self.codeResentTime;
            }
            self.codeResentTime = '0:' + self.codeResentTime;
          }, 1000);
      return;
    },
    nextDigit: function(event) {
      var name = event.target.name;
      if (!name)
      {
        return;
      }
      let keyCode = (event.keyCode ? event.keyCode : event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode != 39) {
        event.preventDefault();
        return false;
      }
      var n = name.replace('digits', '');
      if (n == 0)
      {
        this.$refs.digits1.focus();
      }
      else if (n == 1)
      {
        this.$refs.digits2.focus();
      }
      else if (n == 2)
      {
        this.$refs.digits3.focus();
      }
      else if (n == 3)
      {
        this.$refs.digits4.focus();
      }
      else if (n == 4)
      {
        this.$refs.digits5.focus();
      }
      else if (n == 5)
      {
        this.readyToSend = true;
      }
    },
    prevDigit: function(event) {
      var name = event.target.name;
      var n = name.replace('digits', '');
      if (n == 1)
      {
        this.$refs.digits0.focus();
      }
      else if (n == 2)
      {
        this.$refs.digits1.focus();
      }
      else if (n == 3)
      {
        this.$refs.digits2.focus();
      }
      else if (n == 4)
      {
        this.$refs.digits3.focus();
      }
      else if (n == 5)
      {
        this.$refs.digits4.focus();
      }
    },
    paste: function(event) {
      var text = event.clipboardData.getData('text/plain').trim();
      if (text.match(/^[0-9]{6}$/))
      {
        this.digit0 = text.substr(0, 1);
        this.digit1 = text.substr(1, 1);
        this.digit2 = text.substr(2, 1);
        this.digit3 = text.substr(3, 1);
        this.digit4 = text.substr(4, 1);
        this.digit5 = text.substr(5, 1);
        this.readyToSend = true;
      }
    },
    confirmCode: function() {
      if (this.step == 'register-confirm')
      {
          this.register();
      }
      else if (this.step == 'password-reset-confirm')
      {
          this.remind();
      }
      else if (this.step == '2fa-confirm')
      {
          this.login();
      }
    },
    cancelCode: function() {
      if (this.step == 'register-confirm')
      {
          this.errors = {};
          this.step = 'register';
      }
      else if (this.step == 'password-reset-confirm')
      {
          this.errors = {};
          this.step = 'password-reset';
      }
      else if (this.step == 'new-password')
      {
          this.errors = {};
          this.step = '';
      }
      else if (this.step == '2fa-confirm')
      {
          this.errors = {};
          this.step = '';
      }
    },
    resendCode: function() {
      if (this.step == 'register-confirm')
      {
        this.step = 'register-confirm-resend';
        this.register();
      }
      else if (this.step == 'password-reset-confirm')
      {
        this.step = 'password-reset-resend';
        this.remind();
      }
      else if (this.step == '2fa-confirm')
      {
        this.step = '2fa-resend';
        this.login();
      }
    }
  },
  beforeUmount: function(){
    clearInterval(this.expirationInterval);
    clearInterval(this.codeResentInterval);
  },
  computed: {
    profile() {
      return this.$profile();
    }
  }
};
</script>

<style>
ul.roles {
  list-style-type: none;
  padding: 0;
}
ul.roles li {
  padding: 0;
  padding-bottom: 8px;
}
</style>
